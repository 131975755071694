<template>
  <div id="app">
    <div class="triangle-shape" />
    <div class="white-shape" />
    <div class="row header">
      <img src="logo.svg" />
    </div>
    <div class="row title">
      <div style="font-weight: 500;">
        FICHES DE DONNÉES DE SÉCURITÉ
        <div class="translation">SAFETY DATA SHEETS</div>
      </div>
      <div style="font-weight: 900;">
        STATUT DES TÉLÉCHARGEURS
        <div class="translation">DOWNLOADERS STATUS</div>
      </div>
    </div>
    <div class="row">
      <table class="status" v-if="activeBrands.length">
        <thead>
          <tr>
            <th></th>
            <th>
              MARQUE
              <br /><span class="translation">BRAND</span>
            </th>
            <th>
              STATUT DU TEST
              <br /><span class="translation">TEST STATUS</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :key="brand.name" v-for="brand in activeBrands"
            :class="brand.status !== 'SUCCESSFUL' && brand.status !== 'FAILED' ? 'disable' : ''">
            <td class="status-icon">
              <img :src="getIcon(brand.last_success_period)" />
            </td>
            <td>{{ brand.name }}</td>
            <td v-html="getTranslatedLastSuccessPeriod(brand.last_success_period)"></td>
          </tr>
        </tbody>
      </table>
      <table class="status" v-if="disabledBrands.length">
        <thead>
          <tr>
            <th></th>
            <th>
              MARQUE(S) DÉSACTIVÉE(S)
              <br /><span class="translation">DISABLED BRAND(S)</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr :key="brand.name" v-for="brand in disabledBrands"
            :class="brand.status !== 'SUCCESSFUL' && brand.status !== 'FAILED' ? 'disable' : ''">
            <td class="status-icon">
              <img :src="getIcon(brand.last_success_period)" />
            </td>
            <td>{{ brand.name }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="loader" v-else>
        {{ message }}<br />
        <span class="translation">{{ messageEnglish }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      message: 'Chargement des données...',
      messageEnglish: 'Loading data...',
      brands: null
    };
  },
  async mounted() {
    try {
      const response = await this.axios.get('last-status');
      const responseData = response.data;
      this.brands = Object.keys(responseData).map((key) => ({
        name: key,
        ...responseData[key],
      }));
    } catch {
      this.message = 'Service temporairement indisponible.';
      this.messageEnglish = 'Service temporarily unavailable.';
    }
  },
  computed: {
    disabledBrands() {
      return this.brands ? this.brands.filter((brand) => brand.status === 'FAILED') : [];
    },
    activeBrands() {
      return this.brands ? this.brands.filter((brand) => brand.status === 'SUCCESSFUL') : [];
    },
  },
  methods: {
    getIcon(lastSuccessPeriod) {
      if (lastSuccessPeriod === 'last success occurred today') {
        return 'up.svg';
      } else if (lastSuccessPeriod === 'last success within the last 7 days') {
        return 'recentlyDown.svg';
      } else {
        return 'down.svg';
      }
    },
    getTranslatedLastSuccessPeriod(lastSuccessPeriod) {
      const frTranslations = {
        'last success occurred today': 'Dernier téléchargement réussi aujourd\'hui',
        'last success within the last 7 days': 'Dernier téléchargement réussi il y a moins de 7 jours',
        'last success within the last 14 days': 'Pas de téléchargement réussi depuis au moins 7 jours',
      };
      const enTranslations = {
        'last success occurred today': 'Last success download occurred today',
        'last success within the last 7 days': 'Last success download occured the last 7 days',
        'last success within the last 14 days': 'No success download on more than 7 days',
      };
      let frenchTranslation = frTranslations[lastSuccessPeriod] || lastSuccessPeriod;
      let englishTranslation = enTranslations[lastSuccessPeriod] || lastSuccessPeriod;
      return frenchTranslation + '<br /><span class="translation">' + englishTranslation + '</span>';
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  background: url(../public/Pattern_Trait_Gris_10.svg) repeat;
}

#app {
  font-family: 'Poppins', sans-serif;
  background-image: url(../public/voile_rouge_haut_droit.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: top right;
}

.row {
  padding: 15px;
}

.row.header {
  padding-top: 30px;
  text-align: left;
  max-width: 60%;
  margin: 0 auto;
}

.row.header > img {
  width: 300px;
}

.row.title {
  min-height: 100px;
  max-width: 60%;
  margin: 0 auto;
}

.loader,
.row.title {
  color: #ba054d;
  font-size: 30px;
  line-height: 37.5px;
  text-align: left;
}

.loader {
  width: 100%;
  height: 100px;
  font-weight: 300;
}

.status {
  width: 60%;
  border-collapse: collapse;
  margin: 0 auto;
  background-color: #eee;
  margin-bottom: 100px;
  text-align: center;
}

.status td,
.status th {
  padding: 15px 10px;
  text-align: left;
}

.status th {
  background-color: #ba054d;
  color: white;
}

.status tr:nth-child(odd) {
  background-color: #fff;
}

tr.disable {
  color: grey !important;
  background-color: #f7f7f7;
}

tr.disable:nth-child(odd) {
  color: grey !important;
  background-color: #fff;
}

.status .status-icon {
  padding-left: 20px;
  width: 20px;
  text-align: center;
}

.status .status-icon img {
  vertical-align: middle;
}

.translation {
  font-size: 0.8em;
  color: rgb(210, 210, 210);
}
</style>